import "@dej611/spid-react-button/dist/index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import App from "./App";
import CitizenInfo from "./pages/CitizenInfo";
import { Footer } from "./components/Footer";
// import { Header } from "./components/Header";
import {
  Stack
} from "@mui/material";
import "./global.css";

import { Theme } from "./Theme";
import { ThemeProvider } from '@mui/material/styles';
import { Error } from "./pages/Error";
import AppMobile from "./AppMobile";
import { BackToApp } from "./pages/BackToApp";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

interface ProtectedMobileRouteProps {
  element: React.ReactElement;
}

// Funzione per rilevare il dispositivo mobile
const isMobile = () => {
  const userAgent = navigator.userAgent;
  return /android|iphone|ipad|ipod|windows phone/i.test(userAgent);
};

// Funzione per verificare il dominio
const isAllowedDomain = () => {
  const allowedDomains = ["http://localhost:3000", "http://notarify.io", "https://notarify.io"];

  const urlParams = new URLSearchParams(window.location.search);
  const parentOrigin = urlParams.get('parentOrigin');

  return parentOrigin != null && allowedDomains.includes(parentOrigin);
};

// Componente per proteggere la rotta /mobile
const ProtectedMobileRoute: React.FC<ProtectedMobileRouteProps> = ({ element }) => {
  if (isMobile()) {
    return element; // Se è un dispositivo mobile, mostra il componente
  } else if (isAllowedDomain()) {
    return element; // Se non è mobile ma è dal dominio consentito, mostra il componente
  } else {
    return <Navigate to="/" />; // Altrimenti, reindirizza alla pagina principale
  }
};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <Stack gap="40px" height="100vh">
        <BrowserRouter>
          {/* <Header></Header> */}
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/mobile" element={<ProtectedMobileRoute element={<AppMobile />} />} />
            <Route path="/acs" element={<CitizenInfo />} />
            <Route path="/back-to-app" element={<BackToApp />} />
            <Route path="/error" element={<Error />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </Stack>
    </ThemeProvider>
  </React.StrictMode>
);